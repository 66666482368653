import cookies from 'vue-cookies';
import lodash from 'lodash';

import configuration from '@/configurations';
import store from '@/store';

import {
  authentication_repository,
  newsletter_repository,
  notification_repository,
  query_repository,
  user_repository,
} from '@/repositories';

import {
  LICENSE_CONSTANTS,
  USER_CONSTANTS,
} from '@/constants';

import {
  GLOBAL,
} from '@/constants/abilities';
import router from '../../router';

const state = () => ({
  authentication_status: '',
  is_connected: authentication_repository.isValidLocalStorageToken(),
  is_connected_as: false,
  intercom_user_hash: null,
  organization: null,
  token: authentication_repository.getTokenFromLocalStorageIfIsValid(),
  token_data: authentication_repository.isValidLocalStorageToken()
    ? authentication_repository
      .decodeToken(authentication_repository
        .getTokenFromLocalStorageIfIsValid())
    : {},
  user: {},
  news_query_list: [],
  email: authentication_repository.getEmailLocalStorage(),
  password: authentication_repository.getPasswordLocalStorage(),
  user_data: user_repository.getUserDataLocalStorage(),
  user_segments: [],
  newsletter_configuration: {},
  notification_settings: [],
  organization_user_list: [],
  colleague_user_list: [],
  user_actual_experience: null,
  user_global_configuration: {},
  user_property_list: [],
  user_formation_list: [],
  user_experience_list: [],
  user_previous_experience_list: [],
});

const getters = {
  isConnected: (state) => state.is_connected,
  isConnectedAs: (state) => state.is_connected_as,
  authenticationStatus: (state) => state.authentication_status,
  token: (state) => state.token,
  tokenData: (state) => state.token_data,
  newsQueryList: (state) => state.news_query_list,
  email: (state) => state.email,
  password: (state) => state.password,
  userData: (state) => state.user_data,
  userIsAlpha: (state) => state.user_data.tester_level === 'Alpha',
  userIsBeta: (state) => state.user_data.tester_level === 'Beta',
  userSegments: (state) => state.user_segments,
  isEmptyUserSegments: (state) => state.user_segments.length === 0,
  organization: (state) => state.organization,
  newsletterConfiguration: (state) => state.newsletter_configuration,
  notificationSettings: (state) => state.notification_settings,
  organizationUserList: (state) => state.organization_user_list,
  userActualExperience: (state) => state.user_actual_experience,
  userGlobalConfiguration: (state) => state.user_global_configuration,
  userPropertyList: (state) => state.user_property_list,
  userFormationList: (state) => state.user_formation_list,
  userExperienceList: (state) => state.user_experience_list,
  userPreviousExperienceList: (state) => state.user_previous_experience_list,
  colleagueUserList: (state) => state.colleague_user_list,
  userHasSalesPmeLicense: (state) => state.user_data
    .organization_user_license_list
    .map((organization_user_license) => organization_user_license.license.id)
    .includes(LICENSE_CONSTANTS.SALES_PME.ID),
  intercomUserHash: (state) => state.intercom_user_hash,
};

const actions = {
  async isConnectedAs() {
    return cookies.get(GLOBAL.COOKIE.CONNECTED_AS) !== null;
  },
  async isConnected() {
    return cookies.get(GLOBAL.COOKIE.JWT) !== null;
  },
  resetPassword(_, email) {
    return new Promise((resolve, reject) => authentication_repository
      .resetPassword(email)
      .then(resolve)
      .catch(reject)
    );
  },
  updateCurrentPassword(_, data) {
    return new Promise((resolve, reject) => authentication_repository
      .updateCurrentPassword(data)
      .then(resolve)
      .catch(reject)
    );
  },
  updatePassword(_, data) {
    return new Promise((resolve, reject) => authentication_repository
      .updatePassword(data)
      .then(resolve)
      .catch(reject)
    );
  },
  login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      commit('authentication_request');

      return authentication_repository.login(email, password)
        .then((response) => {
          authentication_repository.setTokenLocalStorage(response.jwt);

          // add Cookie to sync auth with v1
          // use the same duration sent by server
          const { jwt } = response;
          const { iat, exp } = authentication_repository.decodeToken(jwt);
          const token_duration = exp - iat;

          cookies.set(GLOBAL.COOKIE.JWT, jwt, token_duration, '/', configuration.cookie_domain);

          commit('authentication_success', jwt);
          store.dispatch('event_capture/handleUserLoggedIn', { jwt }); // posthog identify

          return resolve(response);
        })
        .catch((error) => {
          commit('authentication_error');

          return reject(error);
        });
    });
  },
  // return redirect : if true, redirect after remove jwt and jwt_external
  async checkJwtFromUrl({ dispatch }) {
    const params = new URLSearchParams(window.location.search);
    const query_params = {};

    for (const [key, value] of params.entries()) {
      query_params[key] = value;
    }

    const {
      jwt,
      // jwt_external,
    } = query_params;

    if (jwt) {
      try {
        const is_valid_token = authentication_repository.isValidToken(jwt);

        if (!is_valid_token) {
          console.error('Invalid token expiration date');

          return false;
        }

        const { iat, exp } = authentication_repository.decodeToken(jwt);
        const token_duration = exp - iat;

        cookies.set(GLOBAL.COOKIE.JWT, jwt, token_duration, '/', configuration.cookie_domain);

        return true;
      } catch (error) {
        console.error('checkJwtFromUrl_jwt', error);

        return false;
      }
    }

    const is_connected = await dispatch('isConnected');

    if (is_connected) {
      return false;
    }

    // if (jwt_external) {
    //   try {
    //     const is_valid_token = authentication_repository.isValidToken(jwt_external);

    //     if (!is_valid_token) {
    //       console.error('Invalid token expiration date');

    //       return false;
    //     }

    //     // TODO: Call with Bearer token (not connected)
    //     const jwt_token = await authentication_repository.renewToken(jwt_external);

    //     const {iat, exp} = authentication_repository.decodeToken(jwt_token);
    //     const token_duration = exp - iat;

    //     cookies.set(GLOBAL.COOKIE.JWT, jwt_token, token_duration, '/', configuration.cookie_domain);

    //     return true;
    //   } catch (error) {
    //     console.error('checkJwtFromUrl_jwt_external', error);

    //     return false;
    //   }
    // }

    return false;
  },
  checkJwtFromCookies({ dispatch }) {
    return new Promise((resolve) => {
      const jwt = cookies.get(GLOBAL.COOKIE.JWT);

      const jwt_connected_as = cookies.get(GLOBAL.COOKIE.CONNECTED_AS);

      if (jwt === null && jwt_connected_as === null) {
        dispatch('logout');

        return resolve();
      } else if (jwt === null && jwt_connected_as !== null) {
        const check_jwt_interval = setInterval(() => {
          if (
            cookies.get(GLOBAL.COOKIE.JWT) !== null
            || cookies.get(GLOBAL.COOKIE.CONNECTED_AS) !== null
          ) {
            clearInterval(check_jwt_interval);

            return resolve();
          }
        }, 100);
      } else {
        return resolve();
      }
    });
  },
  async loginWithJwtFromCookies({ commit, dispatch, state }) {
    const is_connected_as = await dispatch('isConnectedAs');

    commit('set_is_connected_as', is_connected_as);

    if (is_connected_as) {
      cookies.set(GLOBAL.COOKIE.JWT, cookies.get(GLOBAL.COOKIE.CONNECTED_AS), 60 * 60, '/', configuration.cookie_domain);
    }

    const jwt = cookies.get(GLOBAL.COOKIE.JWT);

    // vérifier si le jwt est valide ici et dispatch logout si expiré, sinon page blanche
    if (jwt !== null && authentication_repository.isValidToken(jwt)) {
      commit('authentication_success', jwt);
      store.dispatch('event_capture/handleUserLoggedIn', { jwt }); // posthog identify
    } else {
      await dispatch('logout');
    }

    setInterval(async () => {
      const interval_is_connected_as = await dispatch('isConnectedAs');
      const interval_is_connected = await dispatch('isConnected');

      if (
        state.is_connected_as === interval_is_connected_as
        && state.is_connected === interval_is_connected
      ) {
        return;
      }

      if (interval_is_connected_as) {
        user_repository.removeUserDataLocalStorage();
        commit('setUserData', null);
        cookies.set(GLOBAL.COOKIE.JWT, cookies.get(GLOBAL.COOKIE.CONNECTED_AS), 60 * 60, '/', configuration.cookie_domain);
        commit('authentication_success', cookies.get(GLOBAL.COOKIE.CONNECTED_AS));
        dispatch('getUserData');
        commit('set_is_connected_as', true);
        window.location.reload();
      } else if (interval_is_connected && state.is_connected_as === interval_is_connected_as) {
        user_repository.removeUserDataLocalStorage();
        commit('setUserData', null);
        commit('authentication_success', cookies.get(GLOBAL.COOKIE.JWT));
        dispatch('getUserData');
        commit('set_is_connected', true);
        window.location.reload();
      } else {
        dispatch('logout');
      }
    }, 5000);
  },
  async logout({ commit }) {
    try {
      commit('logout');

      user_repository.removeUserDataLocalStorage();
      authentication_repository.removeTokenLocalStorage();
      authentication_repository.removeEmailLocalStorage();
      authentication_repository.removePasswordLocalStorage();

      // identity reset for the event capture (Posthog)
      store.dispatch('event_capture/handleUserLoggedOut');

      const jwt = cookies.get(GLOBAL.COOKIE.JWT);

      // logout from the backend
      if (jwt) {
        await authentication_repository.logout();
      }

      cookies.remove(GLOBAL.COOKIE.JWT, '/', configuration.cookie_domain);
      cookies.remove(GLOBAL.COOKIE.CONNECTED_AS, '/', configuration.cookie_domain);

      router.push({
        path: '/login',
        query: {
          redirect_to: window.location.pathname + window.location.search,
        },
      });
    } catch (e) {
      console.error('From userStore logout error', e);
    }
  },
  setConnection({ commit }, { token }) {
    commit('authentication_success', token);
    store.dispatch('event_capture/handleUserLoggedIn', { jwt: token }); // posthog identify
  },
  getNewsQueryList({ commit, state }) {
    return new Promise((resolve, reject) => {
      return query_repository
        .searchUserQueryList(
          state.token_data.user_id,
          {
            query_type_id_list: [
              3, 4, 14, 15, 20, 21, 22, 1,
            ],
          }
        )
        .then((response) => {
          commit('set_news_queries', response.query_list);

          return resolve(response.query_list);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  setEmail({ commit }, { email }) {
    authentication_repository.setEmailLocalStorage(email);

    commit('setEmail', email);
  },
  setPassword({ commit }, { password }) {
    authentication_repository.setPasswordLocalStorage(password);

    commit('setPassword', password);
  },
  getSegments({ commit, state }) {
    return new Promise((resolve, reject) => {
      const criteria = {
        query_type_id_list: [
          USER_CONSTANTS.QUERY_TYPE_ID.SEGMENT_ACCOUNT,
          USER_CONSTANTS.QUERY_TYPE_ID.CLIENTS,
          USER_CONSTANTS.QUERY_TYPE_ID.PROSPECTS,
          USER_CONSTANTS.QUERY_TYPE_ID.COMPETITORS,
          USER_CONSTANTS.QUERY_TYPE_ID.KEY_ACCOUNTS,
        ],
      };

      return query_repository.searchUserQueryList(state.token_data.user_id, criteria)
        .then(({ query_list }) => {
          commit('setUserSegments', query_list);

          return resolve(query_list);
        })
        .catch(reject);
    });
  },
  filterSegmentsByCompanyId({ getters, dispatch }, company_id) {
    return new Promise((resolve, reject) => {
      if (getters.isEmptyUserSegments) {
        return dispatch('getSegments')
          .then(resolve)
          .catch(reject);
      }

      return resolve(getters.userSegments);
    })
      .then((user_segments) => user_segments
        .filter((segment) => segment.criteria.company_id_list !== undefined
          && segment.criteria.company_id_list.includes(company_id)
        )
      );
  },
  async getUserData({ commit, state }) {
    const user_data = await user_repository.getUserById(state.token_data.user_id);
    const user_data_stringified = JSON.stringify(user_data);

    user_repository.setUserDataLocalStorage(user_data_stringified);

    commit('setUserData', user_data);

    return user_data;
  },
  async getUserById(_, user_id) {
    return user_repository
      .getUserById(user_id)
      .then((response) => {
        return response;
      });
  },
  getLicenseId(_, user) {
    if (
      !Array.isArray(user.organization_user_license_list)
      || user.organization_user_license_list.length === 0
    ) {
      return null;
    }

    let organization_user_license = user.organization_user_license_list.sort((a, b) => a.id - b.id);

    organization_user_license = organization_user_license[organization_user_license.length - 1];

    return lodash.get(organization_user_license, 'license.id', null);
  },
  getLicenseName({ dispatch }, user) {
    return dispatch('getLicenseId', user)
      .then((license_id) => {
        return Object
          .values(LICENSE_CONSTANTS)
          .find((license) => license.ID === license_id)
          .LABEL;
      });
  },
  getOrganizationById({ commit }, organization_id) {
    return user_repository.getOrganizationById(organization_id)
      .then((response) => {
        commit('setOrganization', response);

        return response;
      });
  },
  getOrganizationStatusId(_, organization) {
    return lodash.get(organization, 'status.id', null);
  },
  getOrganizationStatusName({ dispatch }, organization) {
    return dispatch('getOrganizationStatusId', organization)
      .then((status_id) => {
        return Object
          .values(USER_CONSTANTS.STATUS)
          .find((status) => status.ID === status_id)
          .LABEL;
      });
  },
  getStatusId(_, user) {
    return lodash.get(user, 'status.id', null);
  },
  getStatusName({ dispatch }, user) {
    return dispatch('getStatusId', user)
      .then((status_id) => {
        return Object
          .values(USER_CONSTANTS.STATUS)
          .find((status) => status.ID === status_id)
          .LABEL;
      });
  },
  async searchOrganizationUserList({ state, commit }) {
    const users = await user_repository.search({
      organization_id_list: [state.token_data.organization_id],
      offset: 0,
    });

    commit('setOrganizationUserList', users.user_list);

    return users.user_list;
  },
  async searchColleagueUserList({ state, commit }) {
    const users = await user_repository.search({
      organization_id_list: [state.token_data.organization_id],
      status_id_list: [1],
      offset: 0,
    });

    commit('setColleagueUserList', users.user_list);

    return users.user_list;
  },
  async searchUserList(_, input) {
    const {
      user_id_list,
    } = input;

    const users = await user_repository.search({
      id_list: user_id_list,
      offset: 0,
    });

    return users.user_list;
  },
  async getUsersGlobalConfigurations({ commit }) {
    const user_global_configuration = await user_repository.getUsersGlobalConfigurations();

    commit('setUserGlobalConfiguration', user_global_configuration);

    return user_global_configuration;
  },

  async getUserPropertyList({ state, commit }, { name_list }) {
    const response = await user_repository.getUserPropertyList(
      state.user_data.id,
      name_list
    );

    commit('setUserPropertyList', response.user_property_list);

    return response.user_property_list;
  },

  // back send error when user has no formation
  async getUserFormationList({ state, commit }) {
    try {
      const response = await user_repository.getUserFormationList(state.user_data.id);

      const user_formation_list = response.user_formation_list.sort((a, b) => (b.year - a.year));

      commit('setUserFormationList', user_formation_list);

      return user_formation_list;

    } catch (e) {
      console.error(e);
      commit('setUserFormationList', []);

      return [];
    }
  },

  async createFormation({ state }, input) {
    const {
      data,
    } = input;

    const formation = await user_repository.createFormation(state.user_data.id, data);

    return formation;
  },

  async updateFormation({ state }, input) {
    const {
      data,
      formation_id,
    } = input;

    const formation = await user_repository
      .updateFormation(state.user_data.id, formation_id, data);

    return formation;
  },

  async deleteFormation({ state }, input) {
    const {
      formation_id,
    } = input;

    return await user_repository.deleteFormation(state.user_data.id, formation_id);
  },

  // back send error when user has no experience
  async getUserExperienceList({ state, commit }) {
    try {
      const response = await user_repository.getUserExperienceList(state.user_data.id);

      const user_experience_list = response.user_experience_list
        .sort((a, b) => (b.start_year - a.start_year));

      const user_actual_experience = user_experience_list
        .find((experience) => experience.actual_job);

      const user_previous_experience_list = user_experience_list
        .filter((experience) => !experience.actual_job);

      commit('setUserActualExperience', (user_actual_experience) ? user_actual_experience : null);
      commit('setUserExperienceList', user_experience_list);
      commit('setUserPreviousExperienceList', user_previous_experience_list);

      return user_experience_list;

    } catch (e) {
      console.error(e);
      commit('setUserActualExperience', null);
      commit('setUserExperienceList', []);
      commit('setUserPreviousExperienceList', []);

      return [];
    }
  },

  async createExperience({ state }, input) {
    const {
      data,
    } = input;

    const formation = await user_repository.createExperience(state.user_data.id, data);

    return formation;
  },

  async updateExperience({ state }, input) {
    const {
      data,
      experience_id,
    } = input;

    const formation = await user_repository
      .updateExperience(state.user_data.id, experience_id, data);

    return formation;
  },

  async deleteExperience({ state }, input) {
    const {
      experience_id,
    } = input;

    return await user_repository.deleteExperience(state.user_data.id, experience_id);
  },

  createUserProperty({ state }, { name, value }) {
    return user_repository.createUserProperty({
      user_id: state.user_data.id,
      name,
      value,
    });
  },

  updateUserProperty(_, { id, value }) {
    return user_repository.updateUserProperty(id, { value });
  },

  deleteUserProperty(_, { id }) {
    return user_repository.deleteUserProperty(id);
  },

  async updateUser({ commit }, { user_id, input }) {
    const updated_user = await user_repository.updateUser(user_id, input);

    commit('setUserData', updated_user);

    return updated_user;
  },

  async getNotificationsSettings({ state, commit }) {
    try {
      const [
        newsletter_configuration,
        notification_settings,
      ] = await Promise.all([
        newsletter_repository.getUserConfiguration({
          organization_id: state.user_data.organization.id,
          user_id: state.user_data.id,
        }),
        notification_repository.getSettings(),
      ]);

      commit('setNewsletterConfiguration', newsletter_configuration);
      commit('setNotificationSettings', notification_settings);

      return;
    } catch (error) {
      console.error('getNotificationsSettings_error', error);

      commit(
        'setNewsletterConfiguration',
        {
          blocked_query_id_list: [],
          newsletter_aggregated: false,
          newsletter_disabled: false,
        }
      );
      commit('setNotificationSettings', []);

      return;
    }
  },

  async updateNewsletterConfiguration({ state, commit }, data) {
    try {
      const newsletter_configuration = await newsletter_repository.updateUserConfiguration({
        data,
        organization_id: state.user_data.organization.id,
        user_id: state.user_data.id,
      });

      commit('setNewsletterConfiguration', newsletter_configuration);

      return;
    } catch (error) {
      console.error('updateUserConfiguration_error', error);

      return;
    }
  },

  async updateNotificationSettings({ commit }, data) {
    try {
      const notification_settings = await notification_repository.updateSettings(data);

      commit('setNotificationSettings', notification_settings);

      return;
    } catch (error) {
      console.error('updateNotificationSettings_error', error);

      return;
    }
  },

  async getIntercomVerifiedHash({ commit }) {
    try {
      const intercom_user_hash = await user_repository.getIntercomVerifiedHash();

      commit('setIntercomUserHash', intercom_user_hash);

      return intercom_user_hash;
    } catch (error) {
      console.error('getIntercomVerifiedHash_error', error);

      return null;
    }
  },

  setHideAskTemplatePopup(_, value) {
    return cookies.set(
      'hide_ask_template_popup',
      value,
      'Infinity', // persistent cookie
      '/',
      configuration.cookie_domain
    );
  },
};

const mutations = {
  authentication_request(state) {
    state.authentication_status = 'loading';
  },
  authentication_success(state, token) {
    state.authentication_status = 'success';
    state.is_connected = true;
    state.token = token;
    state.token_data = authentication_repository.decodeToken(token);
  },
  authentication_error(state) {
    state.authentication_status = 'error';
    state.is_connected = false;
  },
  set_is_connected_as(state, is_connected_as) {
    state.is_connected_as = is_connected_as;
  },
  set_news_queries(state, query_list) {
    state.news_query_list = query_list;
  },
  logout(state) {
    state.status = '';
    state.token = null;
    state.is_connected = false;
    state.is_connected_as = false;
    state.email = '';
    state.password = '';
    state.user_data = null;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setIntercomUserHash(state, intercom_user_hash) {
    state.intercom_user_hash = intercom_user_hash;
  },
  setPassword(state, password) {
    state.password = password;
  },
  setUserData(state, user_data) {
    state.user_data = user_data;
  },
  setUserSegments(state, user_segments) {
    state.user_segments = user_segments;
  },
  setNewsletterConfiguration(state, newsletter_configuration) {
    state.newsletter_configuration = newsletter_configuration;
  },
  setNotificationSettings(state, notification_settings) {
    state.notification_settings = notification_settings;
  },
  setOrganization(state, organization) {
    state.organization = organization;
  },
  setOrganizationUserList(state, organization_user_list) {
    state.organization_user_list = organization_user_list;
  },
  setColleagueUserList(state, colleague_user_list) {
    state.colleague_user_list = colleague_user_list;
  },
  setUserActualExperience(state, user_actual_experience) {
    state.user_actual_experience = user_actual_experience;
  },
  setUserGlobalConfiguration(state, user_global_configuration) {
    state.user_global_configuration = user_global_configuration;
  },
  setUserPropertyList(state, user_property_list) {
    state.user_property_list = user_property_list;
  },
  setUserFormationList(state, user_formation_list) {
    state.user_formation_list = user_formation_list;
  },
  setUserExperienceList(state, user_experience_list) {
    state.user_experience_list = user_experience_list;
  },
  setUserPreviousExperienceList(state, user_previous_experience_list) {
    state.user_previous_experience_list = user_previous_experience_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
