export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "text_assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous a assigné la liste"])},
        "text_clicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a cliqué dans votre mail."])},
        "text_customer_inquiry_done_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre"])},
        "text_customer_inquiry_done_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demande d’enquête"])},
        "text_customer_inquiry_done_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concernant"])},
        "text_customer_inquiry_done_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a abouti avec succès."])},
        "text_customer_inquiry_fail_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre"])},
        "text_customer_inquiry_fail_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demande d’enquête"])},
        "text_customer_inquiry_fail_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concernant"])},
        "text_customer_inquiry_fail_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'a pas pu aboutir."])},
        "text_customer_inquiry_partially_done_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des élements vous attendent au sujet de votre"])},
        "text_customer_inquiry_partially_done_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demande d’enquête"])},
        "text_customer_inquiry_partially_done_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concernant"])},
        "text_nylas_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre boîte mail est bien synchronisée avec Nomination. Mail utilisé:"])},
        "text_nylas_disconnected_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre boîte mail n’est plus synchronisée avec Nomination. Veuillez vous reconnecter sur votre "])},
        "text_nylas_disconnected_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil "])},
        "text_opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a ouvert votre mail."])},
        "text_replied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a répondu à votre mail."])},
        "text_sequence_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre séquence:"])},
        "text_sequence_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vient de se terminer. Retrouvez les résultats"])},
        "text_sequence_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
        "text_smart_mailing_not_enough_credits_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre campagne"])},
        "text_smart_mailing_not_enough_credits_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'a pas pu être envoyée car vous n'avez pas assez de crédits. Veuillez vous rapprocher du service client."])},
        "the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])}
      }
    }
  })
}
