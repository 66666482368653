import {
  notification_repository,
} from '@/repositories';

const state = () => ({
  notification_list: [],
  notification_not_viewed_list: [],
});

const getters = {
  notificationList: (state) => state.notification_list,
  notificationNotViewedList: (state) => state.notification_not_viewed_list,
};

const actions = {
  async searchNotifications({ commit, state }, input) {
    const {
      offset,
    } = input;

    const {
      notification_list,
    } = await notification_repository.searchNotifications(input);

    notification_list.forEach((notification) => {
      notification.parsed_payload = JSON.parse(notification.payload);
    });

    if (offset === 0) {
      commit('set_notification_list', notification_list);

      return notification_list;
    }

    commit('set_notification_list', state.notification_list.concat(notification_list));

    return state.notification_list.concat(notification_list);
  },
  async getNotificationsNotViewed({ commit }) {
    const {
      notification_list,
    } = await notification_repository.getNotificationsNotViewed({
      limit: 5,
      offset: 0,
    });

    notification_list.forEach((notification) => {
      notification.parsed_payload = JSON.parse(notification.payload);
    });

    commit('set_notification_not_viewed_list', notification_list);

    return notification_list;
  },
  async setNotificationsAsViewed({ state }) {
    const notification_id_list = state.notification_not_viewed_list
      .filter((notification) => notification.viewed_at === null)
      .map((notification) => notification.id);

    return Promise.all(
      notification_id_list
        .map((notification_id) => notification_repository.setNotificationAsViewed(notification_id))
    );
  },
};

const mutations = {
  set_notification_list(state, notification_list) {
    state.notification_list = notification_list;
  },
  set_notification_not_viewed_list(state, notification_not_viewed_list) {
    state.notification_not_viewed_list = notification_not_viewed_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
