export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "link_company_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes entreprises suivies"])},
        "link_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon IA"])},
        "link_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes alertes"])},
        "link_contact_segments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes contacts suivis"])},
        "link_credit_consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes crédits"])},
        "link_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon tableau de bord"])},
        "link_email_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion boîte mail"])},
        "link_email_rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi location email"])},
        "link_exports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes exports"])},
        "link_licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
        "link_lists_monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi listes de prospection"])},
        "link_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
        "link_manager_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon accès manager"])},
        "link_my_saved_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes recherches sauvegardées"])},
        "link_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon réseau"])},
        "link_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes notifications"])},
        "link_notifications_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes préférences de notification"])},
        "link_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
        "link_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil & mon IA"])},
        "link_requests_and_inquiries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes demandes d'enquêtes"])},
        "link_templates_and_signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes templates et signatures"])},
        "link_test_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes  emails test"])},
        "menu_group_access_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès manager"])},
        "menu_group_my_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon activité"])},
        "menu_group_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon paramétrage"])},
        "menu_group_my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
        "tab_my_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon réseau"])},
        "tab_my_netwrok_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les actus de mon réseau"])},
        "credit_consumption_consulted_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon suivi conso détaillé : fiches consultées"])},
        "credit_consumption_connections_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon suivi conso détaillé : connexions à la plateforme"])},
        "credit_consumption_business_alerts_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon suivi conso détaillé : alertes business générées"])},
        "credit_consumption_lead_suggestions_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon suivi conso détaillé : leads suggérés"])},
        "calendar_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier"])},
        "barchart_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["répartition par secteurs et fonctions"])},
        "need_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d’aide ?"])},
        "ask_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez-nous !"])},
        "smart_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart scoring"])}
      },
      "en": {
        
      }
    }
  })
}
