export class WebSocketService {
  constructor(url) {
    this.url = url;
    this.socket = null;
    this.event_listeners = new Map();
    this.web_socket_renewal_watcher = null;
  }

  connect() {
    if (this.socket) {
      return;
    }

    if (this.web_socket_renewal_watcher !== null) {
      clearTimeout(this.web_socket_renewal_watcher);
      this.web_socket_renewal_watcher = null;
    }

    this.socket = new WebSocket(this.url, 'echo-protocol');

    this.socket.onopen = () => {};

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      this.dispatchEvent(data.type, data);
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    this.socket.onclose = () => {
      this.socket = null;

      this.web_socket_renewal_watcher = setTimeout(() => {
        this.connect();
      }, 5000);
    };
  }

  sendMessage(type_id, payload) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type_id, ...payload }));
    } else {
      console.warn("WebSocket not connected !");
    }
  }

  addEventListener(type_id, callback) {
    if (!this.event_listeners.has(type_id)) {
      this.event_listeners.set(type_id, []);
    }

    this.event_listeners.get(type_id).push(callback);
  }

  dispatchEvent(type_id, payload) {
    if (this.event_listeners.has(type_id)) {
      this.event_listeners.get(type_id).forEach((callback) => callback(payload));
    }
  }
}
