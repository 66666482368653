import {
  school_repository,
} from '@/repositories';

const state = () => ({
  school_list: [],
});

const getters = {
  schoolList: (state) => state.school_list,
};

const actions = {
  async getSchools({ commit }, criteria) {
    let school_list = [];

    try {
      school_list = await school_repository.searchSchools(criteria);
    } catch (error) {
      console.error('Error in getSchools', error);
    }

    commit('set_school_list', school_list);

    return school_list;
  },
};

const mutations = {
  set_school_list(state, school_list) {
    state.school_list = school_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
