'use strict';

const WEBSOCKET_TYPES = {
  NOTIFICATION: 1,
  CONTACT_STATUS: 2,
  INTERACTION: 3,
  DELETED_INTERACTION: 4,
};

export {
  WEBSOCKET_TYPES,
};
