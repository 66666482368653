import Vuex from 'vuex';

import aiStore from '@/store/modules/aiStore';
import appLayoutStore from '@/store/modules/appLayoutStore';
import businessAlertStore from '@/store/modules/businessAlertStore';
import campaignTargetStore from '@/store/modules/campaigns/campaignTargetStore';
import campaignTargetListStore from '@/store/modules/campaigns/campaignTargetListStore';
import creditStore from '@/store/modules/creditStore';
import emailSignatureStore from '@/store/modules/emailSignatureStore';
import emailStore from '@/store/modules/emailStore';
import eventCaptureStore from '@/store/modules/eventCaptureStore';
import exportStore from '@/store/modules/exportStore';
import fileStorageStore from '@/store/modules/fileStorageStore';
import historyStore from '@/store/modules/historyStore';
import inquiryStore from '@/store/modules/inquiryStore';
import notificationStore from '@/store/modules/notificationStore';
import nylasStore from '@/store/modules/nylasStore';
import queryStore from '@/store/modules/queryStore';
import quickSearchStore from '@/store/modules/quickSearchStore';
import resultStore from '@/store/modules/sequence/resultStore';
import schoolStore from '@/store/modules/schoolStore';
import sequenceDetailStore from '@/store/modules/sequence/sequenceDetailStore';
import sequenceStore from '@/store/modules/sequence/sequenceStore';
import sequenceTargetListStore from '@/store/modules/sequence/sequenceTargetListStore';
import tagStore from '@/store/modules/tagStore';
import targetStore from '@/store/modules/sequence/targetStore';
import taskStore from '@/store/modules/sequence/taskStore';
import skalinStore from '@/store/modules/skalinStore';
import smartMailingStore from '@/store/modules/smartMailingStore';
import toastStore from '@/store/modules/toastStore';
import userStore from '@/store/modules/userStore';
import websocketStore from '@/store/modules/websocketStore';

export default new Vuex.Store({
  modules: {
    ai: aiStore,
    app_layout: appLayoutStore,
    business_alert: businessAlertStore,
    campaign_target: campaignTargetStore,
    campaign_target_list: campaignTargetListStore,
    credit: creditStore,
    email_signature: emailSignatureStore,
    email: emailStore,
    event_capture: eventCaptureStore,
    export: exportStore,
    file_storage: fileStorageStore,
    history: historyStore,
    inquiry: inquiryStore,
    notification: notificationStore,
    nylas: nylasStore,
    query: queryStore,
    quick_search: quickSearchStore,
    result: resultStore,
    school: schoolStore,
    sequence_detail: sequenceDetailStore,
    sequence: sequenceStore,
    sequence_target_list: sequenceTargetListStore,
    skalin: skalinStore,
    tag: tagStore,
    target: targetStore,
    task: taskStore,
    smart_mailing: smartMailingStore,
    toast: toastStore,
    user: userStore,
    websocket: websocketStore,
  },
});
