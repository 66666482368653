import {
  WebSocketService,
} from "@/plugins/websocket";

const state = () => ({
  websocket_service: null,
});

const getters = {
  // schoolList: (state) => state.school_list,
};

const actions = {
  initWebSocket({ commit }, url) {
    const websocket_service = new WebSocketService(url);

    websocket_service.connect();

    commit("set_websocket_service", websocket_service);
  },
  onMessage({ state }, { type_id, callback }) {
    if (state.websocket_service) {
      state.websocket_service.addEventListener(type_id, callback);
    }
  },
  sendMessage({ state }, { type_id, payload }) {
    if (state.websocket_service) {
      state.websocket_service.sendMessage(type_id, payload);
    }
  },
};

const mutations = {
  set_websocket_service(state, websocket_service) {
    state.websocket_service = websocket_service;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
