import {
  quick_search_repository,
  search_repository,
} from '@/repositories';

const QUICK_SEARCH_ITEMS_LIMIT = 6;

const actions = {
  async getQuickSearchResults(_, quick_search) {
    const criteria = {
      quick_search,
      limit: QUICK_SEARCH_ITEMS_LIMIT,
    };

    const result = await quick_search_repository.getQuickSearchResults(criteria);

    return result;
  },
  async searchAccountQuicksearch(_, input) {
    const result = await search_repository.searchAccountQuicksearch(input);

    return result.account_list;
  },
};

export default {
  actions,
  namespaced: true,
};
