import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import VueIntercom from '@homebaseai/vue3-intercom';
import moment from 'moment';
import {
  abilitiesPlugin,
} from '@casl/vue';
import App from '@/App.vue';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import defineAbilitiesFor from '@/plugins/ability';
import router from '@/router';
import store from '@/store';
import AppLayout from '@/components/layouts/AppLayout.vue';
import DefaultLayout from '@/components/layouts/DefaultLayout.vue';
import LoginLayout from '@/components/layouts/LoginLayout.vue';
import PersonalSpaceLayout from '@/components/layouts/PersonalSpaceLayout.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js';
import 'vue3-prism/lib/Vue3Prism.css';

import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

import('froala-editor/js/froala_editor.pkgd.min.js');
import('froala-editor/js/plugins.pkgd.min.js');
import('froala-editor/css/froala_editor.pkgd.min.css');
import('froala-editor/css/froala_style.min.css');
import('froala-editor/js/languages/fr.js');

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg';

moment.locale('fr');

const Vue = createApp(App);

const ability = defineAbilitiesFor(null);

Vue.use(
  abilitiesPlugin,
  ability,
  {
    useGlobalProperties: true,
  }
);

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueIntercom);
Vue.use(VueFroala);
Vue.use(Vue3Prism);

Vue.component('VueDatePicker', VueDatePicker);

Vue.component('AppLayout', AppLayout);
Vue.component('DefaultLayout', DefaultLayout);
Vue.component('LoginLayout', LoginLayout);
Vue.component('PersonalSpaceLayout', PersonalSpaceLayout);

Vue.directive('model-debounce', {
  mounted(el, binding, vnode) {
    const debounce = binding.value.debounce;
    const variable_name = binding.value.name;

    setTimeout(() => {
      vnode.el.value = binding.instance[variable_name];
    }, 1);

    let timeout_item = null;

    const handler = function(e) {
      clearTimeout(timeout_item);

      if (binding.instance[variable_name] === e.target.value) {
        return;
      }

      timeout_item = setTimeout(() => {
        binding.instance[variable_name] = e.target.value;
        vnode.el.dispatchEvent(new CustomEvent('input'));
      }, debounce);
    };

    el.addEventListener('input', handler);
  },
});

Vue.use(i18n);
Vue.use(vuetify);
Vue.use(router);
Vue.use(store);

Vue.config.globalProperties.$filters = {
  createDebounce() {
    let timeout = null;

    return function (fnc, delay) {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        fnc();
      }, delay || 500);
    };
  },
  formatDate(value) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY');
    }
  },
  formatDateInMonthAndYear(value) {
    if (value) {
      return moment(String(value)).format('MMM YYYY');
    }
  },
  goTo(url) {
    if (url) {
      window.location.href = url;
    }
  },
  humanizeDate(value) {
    if (value) {
      if (moment().diff(moment(value), 'days') > 8) {
        return `le ${moment(value).format('Do MMMM YYYY')}`;
      }

      return `il y a ${moment(value).fromNow(true)}`;
    }
  },
  humanizeNumber(value) {
    let humanized_number = '';
    let counter = 0;

    const string = String(value);

    for (let i = string.length - 1; i >= 0; i -= 1) {
      if (counter % 3 === 0 && counter !== string.length) {
        humanized_number = string[i] + ' ' + humanized_number;
      } else {
        humanized_number = string[i] + humanized_number;
      }

      counter += 1;
    }

    return humanized_number.trim();
  },
  moment(value, format) {
    return moment(value).format(format);
  },
  truncate(text, length, suffix) {
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  },
};

Vue.mount('#app');
