'use strict';

const NOTIFICATION_LIMIT = 40;

const NOTIFICATION_TYPES = {
  ALERT: 1,
  LEAD: 2,
  ENGAGEMENT: 3,
  CAMPAIGN: 4,
  REMINDER: 5,
  NETWORK: 6,
  ASSIGNMENT: 7,
  NYLAS_DISCONNECTED: 8,
  CUSTOMER_INQUIRY_DONE: 9,
  NYLAS_CONNECTED: 10,
  NOT_ENOUGH_SMART_MAILING_CREDITS: 11,
  SEQUENCE_DONE: 12,
};

export {
  NOTIFICATION_LIMIT,
  NOTIFICATION_TYPES,
};
