import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class NotificationRepository {

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async searchNotifications(params) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/notifications/list`,
      {
        params,
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async getNotificationsNotViewed(params) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/notifications/list/not_viewed`,
      {
        params,
      }
    );

    return result.data;
  }

  /**
   * @param {Number} notification_id
   *
   * @returns {Promise}
   */
  async setNotificationAsViewed(notification_id) {
    const result = await axios_nomination.post(`${configuration.api_host}/v1/notifications/${notification_id}/viewed`);

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async getSettings() {
    const result = await axios_nomination.get(`${configuration.api_host}/v1/notifications/settings`);

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async updateSettings(data) {
    const result = await axios_nomination
      .put(
        `${configuration.api_host}/v1/notifications/settings`,
        data
      );

    return result.data;
  }
}

const notification_repository = new NotificationRepository();

export {
  notification_repository,
};
