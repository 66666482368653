'use strict';

const INQUIRY_TYPE_ID = {
  FLAG_DECISION_MAKER: 1,
  FLAG_COMPANY: 2,
  SEARCH_MOBILE: 3,
  SEARCH_DECISION_MAKER: 4,
};

const INQUIRY_SUB_TYPE_ID = {
  DECISION_MAKER_FUNCTION: 1,
  DECISION_MAKER_EMAIL: 2,
  DECISION_MAKER_PHONE: 3,
  DECISION_MAKER_ASSISTANT: 4,
  DECISION_MAKER_LINKEDIN: 5,
  DECISION_MAKER_OTHER: 6,
  COMPANY_ADDRESS: 7,
  COMPANY_PHONE: 8,
  COMPANY_STRUCTURE: 9,
  COMPANY_FINANCIAL_DATA: 10,
  COMPANY_OTHER: 11,
  MOBILE_NUMBER: 12,
  SEARCH_DECISION_MAKER: 15,
};

const STATUS_ID = {
  TODO: 1,
  IN_PROGRESS: 2,
  PRE_DONE: 3,
  DONE: 4,
  FAIL: 5,
  DRAFT: 6,
  PRE_FAIL: 7,
  PRE_PARTIALLY_DONE: 8,
  PARTIALLY_DONE: 9,
};

const OPTED_OUT = 'OPTED_OUT';

const INQUIRY_CONSTANTS = {
  INQUIRY_PER_PAGE_COUNT: 20,
  INQUIRY_SUB_TYPE_ID,
  INQUIRY_TYPE_ID,
  OPTED_OUT,
  STATUS_ID,
};

export {
  INQUIRY_CONSTANTS,
};
