<template>
  <div
    class="notification"
    :class="{ 'notification--small': small }"
  >
    <div
      class="icon"
      :class="icon_style"
    >
      <svg-element
        :icon="icon_name"
        size="20"
      />
    </div>

    <div class="data">
      <div class="main">
        <div
          class="text"
          :class="small ? 'nds-text-p' : 'nds-text-p-big'"
        >
          <template v-if="notification.notification_type_id === NOTIFICATION_TYPES.ENGAGEMENT">
            <template v-if="notification.parsed_payload.engagement.tracking_status === 'opened'">
              <a
                class="contact-link"
                :href="`${configuration.desk_v1_host}/contact/${notification.parsed_payload.contact.id}`"
                target="_blank"
              >
                <!-- eslint-disable-next-line vue/max-len -->
                {{ notification.parsed_payload.contact.first_name }} {{ notification.parsed_payload.contact.last_name }}
              </a>

              {{ $t('text_opened') }}
            </template>

            <template v-else-if="notification.parsed_payload.engagement.tracking_status === 'clicked'">
              <a
                class="contact-link"
                :href="`${configuration.desk_v1_host}/contact/${notification.parsed_payload.contact.id}`"
                target="_blank"
              >
                <!-- eslint-disable-next-line vue/max-len -->
                {{ notification.parsed_payload.contact.first_name }} {{ notification.parsed_payload.contact.last_name }}
              </a>

              {{ $t('text_clicked') }}
            </template>

            <template v-else-if="notification.parsed_payload.engagement.tracking_status === 'replied'">
              <a
                class="contact-link"
                :href="`${configuration.desk_v1_host}/contact/${notification.parsed_payload.contact.id}`"
                target="_blank"
              >
                <!-- eslint-disable-next-line vue/max-len -->
                {{ notification.parsed_payload.contact.first_name }} {{ notification.parsed_payload.contact.last_name }}
              </a>

              {{ $t('text_replied') }}
            </template>
          </template>

          <template v-else-if="notification.notification_type_id === NOTIFICATION_TYPES.REMINDER">
            {{ notification.parsed_payload.reminder.purpose }}
          </template>

          <template v-else-if="notification.notification_type_id === NOTIFICATION_TYPES.ASSIGNMENT">
            <!-- eslint-disable-next-line vue/max-len -->
            {{ notification.parsed_payload.assigner_first_name }} {{ notification.parsed_payload.assigner_last_name }}
            {{ $t('text_assigned') }}
            {{ notification.parsed_payload.query_name }}.
          </template>

          <template
            v-else-if="notification.notification_type_id === NOTIFICATION_TYPES.NYLAS_DISCONNECTED"
          >
            {{ $t('text_nylas_disconnected_1') }}

            <router-link
              class="profile-link"
              :to="{ path: '/personal-space/email-connection'}"
              target="_blank"
            >
              {{ $t('text_nylas_disconnected_2') }}
            </router-link>.
          </template>

          <template
            v-else-if="notification
              .notification_type_id === NOTIFICATION_TYPES.CUSTOMER_INQUIRY_DONE"
          >
            <template
              v-if="notification.parsed_payload.status_id === INQUIRY_CONSTANTS.STATUS_ID.PRE_DONE"
            >
              {{ $t('text_customer_inquiry_done_1') }}

              <router-link
                class="customer-inquiry-link"
                :to="{ path: '/reports'}"
                target="_blank"
              >
                {{ $t('text_customer_inquiry_done_2') }}
              </router-link>

              {{ $t('text_customer_inquiry_done_3') }}

              <a
                v-if="notification.contact_id"
                class="contact-link"
                :href="`${configuration.desk_v1_host}/contact/${notification.parsed_payload.contact.id}`"
                target="_blank"
              >
                <!-- eslint-disable-next-line vue/max-len -->
                {{ notification.parsed_payload.contact.first_name }} {{ notification.parsed_payload.contact.last_name }}
              </a>

              <a
                v-else
                class="company-link"
                :href="`${configuration.desk_v1_host}/account/${notification.parsed_payload.company.id}`"
                target="_blank"
              >
                {{ notification.parsed_payload.company.name }}
              </a>

              {{ $t('text_customer_inquiry_done_4') }}
            </template>

            <template
              v-else-if="notification.parsed_payload
                .status_id === INQUIRY_CONSTANTS.STATUS_ID.PRE_FAIL"
            >
              {{ $t('text_customer_inquiry_fail_1') }}

              <router-link
                class="customer-inquiry-link"
                :to="{ path: '/reports'}"
                target="_blank"
              >
                {{ $t('text_customer_inquiry_fail_2') }}
              </router-link>

              {{ $t('text_customer_inquiry_fail_3') }}

              <a
                v-if="notification.contact_id"
                class="contact-link"
                :href="`${configuration.desk_v1_host}/contact/${notification.parsed_payload.contact.id}`"
                target="_blank"
              >
                <!-- eslint-disable-next-line vue/max-len -->
                {{ notification.parsed_payload.contact.first_name }} {{ notification.parsed_payload.contact.last_name }}
              </a>

              <a
                v-else
                class="company-link"
                :href="`${configuration.desk_v1_host}/account/${notification.parsed_payload.company.id}`"
                target="_blank"
              >
                {{ notification.parsed_payload.company.name }}
              </a>

              {{ $t('text_customer_inquiry_fail_4') }}
            </template>

            <template v-else>
              {{ $t('text_customer_inquiry_partially_done_1') }}

              <router-link
                class="customer-inquiry-link"
                :to="{ path: '/reports'}"
                target="_blank"
              >
                {{ $t('text_customer_inquiry_partially_done_2') }}
              </router-link>

              {{ $t('text_customer_inquiry_partially_done_3') }}

              <a
                v-if="notification.contact_id"
                class="contact-link"
                :href="`${configuration.desk_v1_host}/contact/${notification.parsed_payload.contact.id}`"
                target="_blank"
              >
                <!-- eslint-disable-next-line vue/max-len -->
                {{ notification.parsed_payload.contact.first_name }} {{ notification.parsed_payload.contact.last_name }}
              </a>

              <a
                v-else
                class="company-link"
                :href="`${configuration.desk_v1_host}/account/${notification.parsed_payload.company.id}`"
                target="_blank"
              >
                {{ notification.parsed_payload.company.name }}
              </a>

              .
            </template>
          </template>

          <template
            v-else-if="notification.notification_type_id === NOTIFICATION_TYPES.NYLAS_CONNECTED"
          >
            {{ $t('text_nylas_connected') }}

            <span class="email">
              {{ notification.parsed_payload.email }}
            </span>.
          </template>

          <template
            v-else-if="notification
              .notification_type_id === NOTIFICATION_TYPES.NOT_ENOUGH_SMART_MAILING_CREDITS"
          >
            {{ $t('text_smart_mailing_not_enough_credits_1') }}

            <router-link
              class="campaign-link"
              :to="`/campaign-details/${notification.parsed_payload.campaign_id}/results`"
              target="_blank"
            >
              {{ notification.parsed_payload.campaign_name }}
            </router-link>

            {{ $t('text_smart_mailing_not_enough_credits_2') }}
          </template>

          <template
            v-else-if="notification.notification_type_id === NOTIFICATION_TYPES.SEQUENCE_DONE"
          >
            {{ $t('text_sequence_1') }}
            {{ notification.parsed_payload.sequence_name }}
            {{ $t('text_sequence_2') }}

            <router-link
              class="sequence-link"
              :to="`/sequence-details/${notification.parsed_payload.sequence_id}/record`"
              target="_blank"
            >
              {{ $t('text_sequence_3') }}
            </router-link>.
          </template>
        </div>

        <div
          v-if="notification.parsed_payload.contact
            && notification.parsed_payload.job
            && notification.parsed_payload.company"
          class="contact"
        >
          <div
            class="job"
            :class="small ? 'nds-text-p-small' : 'nds-text-p'"
          >
            {{ notification.parsed_payload.job.name }}
          </div>

          <div class="separator" />

          <div
            class="company"
            :class="small ? 'nds-text-p-small' : 'nds-text-p'"
          >
            <a
              class="company-link"
              :href="`${configuration.desk_v1_host}/account/${notification.parsed_payload.company.id}`"
              target="_blank"
            >
              {{ notification.parsed_payload.company.name }}
            </a>
          </div>
        </div>
      </div>

      <div
        class="date"
        :class="small ? 'nds-text-p-small' : 'nds-text-p'"
      >
        {{ $t('the') }} {{ $filters.formatDate(notification.sent_at) }}

        <div class="separator" />

        {{ $filters.moment(notification.sent_at, 'HH[h]mm') }}
      </div>
    </div>
  </div>
</template>

<script>
import configuration from '@/configurations';

import {
  INQUIRY_CONSTANTS,
  NOTIFICATION_TYPES,
} from '@/constants';

import SvgElement from '@/components/elements/SvgElement.vue';

export default {
  name: 'NotificationElement',
  components: {
    SvgElement,
  },
  props: {
    notification: Object,
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    configuration,
    INQUIRY_CONSTANTS,
    NOTIFICATION_TYPES,

    icon_name: 'star',
    icon_style: 'icon--blue',
  }),
  mounted() {
    this.initIcon();
  },
  methods: {
    initIcon() {
      switch(this.notification.notification_type_id) {
      case NOTIFICATION_TYPES.ENGAGEMENT:
        if (this.notification.parsed_payload.engagement.tracking_status === 'opened') {
          this.icon_name = 'mail-open';
          this.icon_style = 'icon--blue';
        } else if (this.notification.parsed_payload.engagement.tracking_status === 'clicked') {
          this.icon_name = 'click';
          this.icon_style = 'icon--blue';
        } else {
          this.icon_name = 'reply';
          this.icon_style = 'icon--blue';
        }

        break;

      case NOTIFICATION_TYPES.REMINDER:
        this.icon_name = 'clock';
        this.icon_style = 'icon--blue';

        break;

      case NOTIFICATION_TYPES.ASSIGNMENT:
        this.icon_name = 'star';
        this.icon_style = 'icon--blue';

        break;

      case NOTIFICATION_TYPES.NYLAS_DISCONNECTED:
        this.icon_name = 'disclaimer';
        this.icon_style = 'icon--red';

        break;

      case NOTIFICATION_TYPES.CUSTOMER_INQUIRY_DONE:
        if (this.notification.parsed_payload.status_id === INQUIRY_CONSTANTS.STATUS_ID.PRE_DONE) {
          this.icon_name = 'binoculars';
          this.icon_style = 'icon--green';
        } else if (
          this.notification.parsed_payload.status_id === INQUIRY_CONSTANTS.STATUS_ID.PRE_FAIL
        ) {
          this.icon_name = 'binoculars';
          this.icon_style = 'icon--red';
        } else {
          this.icon_name = 'binoculars';
          this.icon_style = 'icon--blue';
        }

        break;

      case NOTIFICATION_TYPES.NYLAS_CONNECTED:
        this.icon_name = 'check';
        this.icon_style = 'icon--green';

        break;

      case NOTIFICATION_TYPES.NOT_ENOUGH_SMART_MAILING_CREDITS:
        this.icon_name = 'disclaimer';
        this.icon_style = 'icon--red';

        break;

      case NOTIFICATION_TYPES.SEQUENCE_DONE:
        this.icon_name = 'play';
        this.icon_style = 'icon--blue';

        break;

      default:
        this.icon_name = 'star';
        this.icon_style = 'icon--blue';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.notification {
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 16px 0;

  &--small {
    .data {
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
    }
  }
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;

  &--blue {
    svg {
      --color: #{$cornflower-blue};
    }
  }

  &--green {
    svg {
      --color: #{$salem};
    }
  }

  &--red {
    svg {
      --color: #{$geraldine};
    }
  }
}

.data {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.main {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text {

}

.contact {
  display: flex;
  gap: 5px;
}

.job {

}

.separator {
  border-left: 1px solid $amethyst-smoke;
}

.company {
  color: $cornflower-blue;
}

.contact-link,
.company-link {
  color: inherit;
  text-decoration: none;
}

.profile-link,
.customer-inquiry-link,
.campaign-link,
.sequence-link {
  color: $cornflower-blue;
  display: inline-block;
}

.email {
  color: $cornflower-blue;
}

.date {
  color: $amethyst-smoke;
  display: flex;
  gap: 8px;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/notification.json"></i18n>
